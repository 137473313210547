// extracted by mini-css-extract-plugin
export var badge = "ProjectCard-module--badge--9ShLg";
export var badgeText = "ProjectCard-module--badge-text--RzZDz";
export var card = "ProjectCard-module--card--tC+8+";
export var casestudyLink = "ProjectCard-module--casestudy-link--SXq5-";
export var featuredImage = "ProjectCard-module--featured-image--1aCe-";
export var header = "ProjectCard-module--header--Dx5Tr";
export var logo = "ProjectCard-module--logo--1dfDo";
export var logoContainer = "ProjectCard-module--logo-container--sEYkS";
export var projectDescription = "ProjectCard-module--project-description--AylsQ";
export var rectLogo = "ProjectCard-module--rect-logo---9eRv";
export var roundLogo = "ProjectCard-module--round-logo--EbOXd";
export var tagsContainer = "ProjectCard-module--tags-container--XAr4B";