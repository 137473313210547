import React from 'react';
import {Helmet} from 'react-helmet';
import ProjectFilter from './Project/ProjectFilter/ProjectFilter';
import ProjectGrid from './Project/ProjectGrid/ProjectGrid';
import HeadingBanner from '../Shared/HeadingBanner/HeadingBanner';
import FeaturedProjectsCarousel from './Project/FeaturedProjectsCarousel/FeaturedProjectsCarousel';
import useDebouncedEffect from '../../hooks/useDebouncedEffect';

// imported it for using carousel
const $ = typeof window !== `undefined` ? require('jquery') : null;

const SOLUTIONS = [
  {value: 'Web Development', label: 'Web Development'},
  {value: 'Product Design', label: 'Product Design'},
  {value: 'Quality Assurance', label: 'Quality Assurance'},
  {value: 'Mobile Applications', label: 'Mobile Applications'},
  {value: 'User Experience', label: 'User Experience'},
  {value: 'User Interface', label: 'User Interface'},
  {value: 'Staff Augmentation', label: 'Staff Augmentation'},
  {value: 'Game Design', label: 'Game Design'},
  {value: 'Game Development', label: 'Game Development'},
  {value: 'Project Management', label: 'Project Management'},
  {value: 'IoT Development', label: 'IoT Development'},
  {value: 'Cloud Services', label: 'Cloud Services'},
  {value: 'MVP Development', label: 'MVP Development'},
  {value: 'Art and Animations', label: 'Art and Animations'},
  {
    value: 'Extensions and Integrations',
    label: 'Extensions and Integrations',
  },
];

const INDUSTRIES = [
  {value: 'Agriculture', label: 'Agriculture'},
  {value: 'Healthcare & Life Sciences', label: 'Healthcare & Life Sciences'},
  {value: 'Manufacturing', label: 'Manufacturing'},
  {value: 'Financial Services', label: 'Financial Services'},
  {value: 'Lifestyle', label: 'Lifestyle'},
  {value: 'Education & eLearning', label: 'Education & eLearning'},
  {value: 'Media & Entertainment', label: 'Media & Entertainment'},
  {value: 'E-Sports & Gaming', label: 'E-Sports & Gaming'},
  {value: 'Retail & eCommerce', label: 'Retail & eCommerce'},
  {value: 'News & Social Applications', label: 'News & Social Applications'},
  {value: 'Photo Editing and Printing', label: 'Photo Editing and Printing'},
  {value: 'Real Estate', label: 'Real Estate'},
  {
    value: 'Workplace Experience & Management',
    label: 'Workplace Experience & Management',
  },
];

const TECHNOLOGIES = [
  {
    label: 'Web',
    options: [
      {value: 'Python', label: 'Python'},
      {value: 'Ruby on Rails', label: 'Ruby on Rails'},
      {value: 'NodeJS', label: 'NodeJS'},
      {value: 'ReactJS', label: 'ReactJS'},
      {value: 'AngularJS', label: 'AngularJS'},
      {value: 'Java', label: 'Java'},
      {value: 'Django', label: 'Django'},
    ],
  },
  {
    label: 'App',
    options: [
      {value: 'iOS (Swift)', label: 'iOS (Swift)'},
      {value: 'iOS (Objective-C)', label: 'iOS (Objective-C)'},
      {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
      {value: 'React Native', label: 'React Native'},
      {value: 'Flutter', label: 'Flutter'},
    ],
  },
  {
    label: 'Game',
    options: [
      {value: 'Unity', label: 'Unity'},
      {value: 'Construct 2', label: 'Construct 2'},
      {value: 'Cocos2d-x', label: 'Cocos2d-x'},
      {value: 'Phaser', label: 'Phaser'},
    ],
  },
  // { label: "Blockchain", options: [{ value: "Ethereum", label: "Ethereum" }] },
  {
    label: 'IoT',
    options: [
      {value: 'IoT Software Development', label: 'IoT Software Development'},
      {value: 'Iot Hardware Development', label: 'Iot Hardware Development'},
    ],
  },
  {
    label: 'Cloud Services',
    options: [
      {value: 'Amazon Web Services', label: 'Amazon Web Services'},
      {value: 'Google Cloud Platform', label: 'Google Cloud Platform'},
      {
        value: 'Google Application Engine',
        label: 'Google Application Engine',
      },
      {
        value: 'Firebase Realtime Database',
        label: 'Firebase Realtime Database',
      },
      {value: 'Heroku', label: 'Heroku'},
      {value: 'BigQuery', label: 'BigQuery'},
      {value: 'Jenkins', label: 'Jenkins'},
      {value: 'Cloud Firestore Database', label: 'Cloud Firestore Database'},
    ],
  },
  {
    label: 'Augmented Reality',
    options: [
      {value: 'ARKit', label: 'ARKit'},
      // { value: "ARCore", label: "ARCore" },
    ],
  },
];

// All Categories
const CATEGORIES = {
  solutions: SOLUTIONS,
  industries: INDUSTRIES,
  technologies: TECHNOLOGIES,
};

const FEATURED_PROJECTS = [
  {
    id: 'decorist',
    caseStudyUrl: '/portfolio/decorist-room-builder',
    featuredImageSrc:
      require('../../assets/images/portfolio/featured-projects/featured-images/decorist.png')
        .default,
    logoSrc: require('../../assets/images/portfolio/featured-projects/logos/decorist.png').default,
    projectDescription: 'An online interior design service that matches you.',
    projectName: 'decorist',
    solutionCategory: 'Web Development',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {value: 'Retail & eCommerce', label: 'Retail & eCommerce'},
        {
          value: 'Photo Editing and Printing',
          label: 'Photo Editing and Printing',
        },
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
        {value: 'Unity', label: 'Unity'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
        {value: 'Jenkins', label: 'Jenkins'},
      ],
    },
  },
  {
    id: 'beatbugs',
    caseStudyUrl: '/portfolio/beatbugs',
    featuredImageSrc:
      require('../../assets/images/portfolio/featured-projects/featured-images/beat-bugs.png')
        .default,
    logoSrc: require('../../assets/images/portfolio/featured-projects/logos/beat-bugs.png').default,
    projectDescription: 'Ranked number 5 in Best-selling Music app category',
    projectName: 'beatbugs',
    solutionCategory: 'Game Development',
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Game Design', label: 'Game Design'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {value: 'Education & eLearning', label: 'Education & eLearning'},
        {value: 'Media & Entertainment', label: 'Media & Entertainment'},
      ],
      technologies: [{value: 'Unity', label: 'Unity'}],
    },
  },
  {
    id: 'sesame',
    caseStudyUrl: '/portfolio/sesame',
    featuredImageSrc:
      require('../../assets/images/portfolio/featured-projects/featured-images/sesame-banner.png')
        .default,
    logoSrc: require('../../assets/images/portfolio/featured-projects/logos/sesame.png').default,
    projectDescription: 'The most loved titles published by Sesame',
    projectName: 'sesame',
    solutionCategory: 'Mobile Applications',
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {value: 'Education & eLearning', label: 'Education & eLearning'},
        {value: 'Media & Entertainment', label: 'Media & Entertainment'},
      ],
      technologies: [{value: 'Unity', label: 'Unity'}],
    },
  },
  {
    id: 'bbqguru',
    caseStudyUrl: '/portfolio/bbqguru',
    featuredImageSrc:
      require('../../assets/images/portfolio/featured-projects/featured-images/bbqguru-banner.png')
        .default,
    logoSrc: require('../../assets/images/portfolio/featured-projects/logos/bbqguru.png').default,
    projectDescription: 'BBQ temperature monitoring/control right from the smartphone.',
    projectName: 'bbqguru',
    solutionCategory: 'Mobile Applications',
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {value: 'Education & eLearning', label: 'Education & eLearning'},
        {value: 'Media & Entertainment', label: 'Media & Entertainment'},
      ],
      technologies: [{value: 'Unity', label: 'Unity'}],
    },
  },
  {
    id: 'jobbox',
    caseStudyUrl: '/portfolio/jobbox',
    featuredImageSrc:
      require('../../assets/images/portfolio/featured-projects/featured-images/jobbox-banner.png')
        .default,
    logoSrc: require('../../assets/images/portfolio/featured-projects/logos/jobbox.png').default,
    projectDescription: 'A peer-to-peer tool and equipment rental and trade platform.',
    projectName: 'jobbox',
    solutionCategory: 'Mobile Applications',
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {value: 'Education & eLearning', label: 'Education & eLearning'},
        {value: 'Media & Entertainment', label: 'Media & Entertainment'},
      ],
      technologies: [{value: 'Unity', label: 'Unity'}],
    },
  },
  {
    id: 'gamerize',
    caseStudyUrl: '/portfolio/gamerize',
    featuredImageSrc:
      require('../../assets/images/portfolio/featured-projects/featured-images/gamerize-banner.png')
        .default,
    logoSrc: require('../../assets/images/portfolio/featured-projects/logos/gamerize.png').default,
    projectDescription: 'A carefully graded learner dictionary',
    projectName: 'gamerize',
    solutionCategory: 'Mobile Applications',
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {value: 'Education & eLearning', label: 'Education & eLearning'},
        {value: 'Media & Entertainment', label: 'Media & Entertainment'},
      ],
      technologies: [{value: 'Unity', label: 'Unity'}],
    },
  },
];

// All Projects
const PROJECTS = [
  {
    id: 'stacks',
    featuredImageSrc: 'stacks.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/stacks.png').default,
    projectDescription:
      'Stacks is an open-source network of decentralized apps and smart contracts built on Bitcoin. ',
    projectName: 'raven',
    categories: {
      solutions: [
        {value: 'Team Augmentation ', label: 'Team Augmentation'},
        {value: 'Rosetta Implementation ', label: 'Rosetta Implementation'},
      ],
      industries: [{value: 'Real Estate', label: 'Real Estate'}],
      technologies: [
        {value: 'Clarity', label: 'Clarity'},
        {value: 'Node Js (Typescript)', label: 'Node Js (Typescript)'},
      ],
    },
  },
  {
    id: 'alexgo',
    featuredImageSrc: 'alexgo.jpg',
    logoSrc: require('../../assets/images/portfolio/projects/logos/alexgo.png').default,
    projectDescription:
      'ALEX is an open-source DeFi development company built on the Stacks Blockchain and modeled on the world’s financial markets.',
    projectName: 'raven',
    categories: {
      solutions: [
        {value: 'Frontend', label: 'Frontend'},
        {value: 'Smart Contracts', label: 'Smart Contracts'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
      ],
      industries: [{value: 'Real Estate', label: 'Real Estate'}],
      technologies: [
        {value: 'Clarity (Smart Contracts)', label: 'Clarity (Smart Contracts)'},
        {value: 'ReactJS', label: 'ReactJS'},
      ],
    },
  },
  {
    id: 'xverse',
    featuredImageSrc: 'xverse.jpg',
    logoSrc: require('../../assets/images/portfolio/projects/logos/xverse.png').default,
    projectDescription:
      'Through Xverse Wallet, users can get an onramp on crypto, send and receive digital assets, and connect to DeFi and other decentralized applications.',
    projectName: 'raven',
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Smart Contract Integration ', label: 'Smart Contract Integration '},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
      ],
      industries: [{value: 'Real Estate', label: 'Real Estate'}],
      technologies: [
        {value: 'React Native', label: 'React Native'},
        {value: 'ReactJS', label: 'ReactJS'},
        {value: 'Node Js (Typescript)', label: 'Node Js (Typescript)'},
      ],
    },
  },
  {
    id: 'life-print',
    featuredImageSrc: 'life-print.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/life-print.png').default,
    projectDescription:
      'Print living photos on the go with Lifeprint. A social combination of AR and portable printers to share your memories all over the world.',
    projectName: 'life-print',
    categories: {
      solutions: [
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {
          value: 'News & Social Applications',
          label: 'News & Social Applications',
        },
        {
          value: 'Photo Editing and Printing',
          label: 'Photo Editing and Printing',
        },
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'NodeJS', label: 'NodeJS'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'iOS (Objective-C)', label: 'iOS (Objective-C)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {
          value: 'Google Application Engine',
          label: 'Google Application Engine',
        },
        {
          value: 'Firebase Realtime Database',
          label: 'Firebase Realtime Database',
        },
      ],
    },
  },
  {
    id: 'draft-kings',
    featuredImageSrc: 'draft-kings.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/draft-kings.png').default,
    projectDescription:
      'A Juggernaut in fantasy sports, DraftKings worked with Tintash on their mobile applications for fantasy sports competitions and sports news.',
    projectName: 'draft-kings',
    isLogoRound: true,
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Staff Augmentation', label: 'Staff Augmentation'},
        {value: 'MVP Development', label: 'MVP Development'},
      ],
      industries: [
        {value: 'Media & Entertainment', label: 'Media & Entertainment'},
        {value: 'E-Sports & Gaming', label: 'E-Sports & Gaming'},
        {
          value: 'News & Social Applications',
          label: 'News & Social Applications',
        },
      ],
      technologies: [
        {value: 'Java', label: 'Java'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'iOS (Objective-C)', label: 'iOS (Objective-C)'},
      ],
    },
  },
  {
    id: 'embr',
    featuredImageSrc: 'embr.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/embr.png').default,
    projectDescription:
      'Embr Labs’ heating and cooling bracelet that lets you take control & make yourself comfortable, anytime, anywhere through the connected application.',
    projectName: 'embr',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'IoT Development', label: 'IoT Development'},
        {value: 'Cloud Services', label: 'Cloud Services'},
      ],
      industries: [
        {
          value: 'Healthcare & Life Sciences',
          label: 'Healthcare & Life Sciences',
        },
        {value: 'Lifestyle', label: 'Lifestyle'},
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'Ruby on Rails', label: 'Ruby on Rails'},
        {value: 'NodeJS', label: 'NodeJS'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {
          value: 'IoT Software Development',
          label: 'IoT Software Development',
        },
        {
          value: 'Firebase Realtime Database',
          label: 'Firebase Realtime Database',
        },
        {value: 'BigQuery', label: 'BigQuery'},
      ],
    },
  },
  {
    id: 'raven',
    featuredImageSrc: 'raven.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/raven.png').default,
    projectDescription:
      'Raven is a platform for Real Estate Agents, by Real Estate Agents, who want to replace big brokerages taking huge money off Agent’s Leads and their commissions.',
    projectName: 'raven',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Staff Augmentation', label: 'Staff Augmentation'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [{value: 'Real Estate', label: 'Real Estate'}],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
      ],
    },
  },

  {
    id: 'common-sense',
    caseStudyUrl: '',
    featuredImageSrc: 'common-sense.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/common-sense.png').default,
    projectDescription:
      'On cell phones and tablets, choose age appropriate content for your child through independent reviews, age ratings, and other information about all types of media.',
    projectName: 'common-sense',
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
      ],
      industries: [
        {value: 'Education & eLearning', label: 'Education & eLearning'},
        {value: 'Media & Entertainment', label: 'Media & Entertainment'},
      ],
      technologies: [
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
      ],
    },
  },
  {
    id: 'care-karts',
    caseStudyUrl: '',
    featuredImageSrc: 'care-karts.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/care-karts.png').default,
    projectDescription:
      'This side scrolling racer starring the Care Bears characters is the ultimate Build it, Fuel it, Chase it adventure!',
    projectName: 'care-karts',
    isLogoRound: true,
    categories: {
      solutions: [
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Game Design', label: 'Game Design'},
        {value: 'Game Development', label: 'Game Development'},
        {value: 'Art and Animations', label: 'Art and Animations'},
      ],
      industries: [{value: 'Media & Entertainment', label: 'Media & Entertainment'}],
      technologies: [{value: 'Unity', label: 'Unity'}],
    },
  },
  {
    id: 'little-pony',
    caseStudyUrl: '',
    featuredImageSrc: 'little-pony.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/little-pony.png').default,
    projectDescription:
      'My Little Pony lets you choose your favorite characters, props, and locations in Equestria and then add your own voice to narrate the tale.',
    projectName: 'little-pony',
    isLogoRound: true,
    categories: {
      solutions: [
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Game Development', label: 'Game Development'},
      ],
      industries: [{value: 'Media & Entertainment', label: 'Media & Entertainment'}],
      technologies: [{value: 'Unity', label: 'Unity'}],
    },
  },
  {
    id: 'stick-sports',
    caseStudyUrl: '',
    featuredImageSrc: 'stick-sports.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/stick-sports.png').default,
    projectDescription:
      'World’s most popular cricket game, easy to play yet hard to master. It is you versus the world. One by one knock over the teams on your way to glory!',
    projectName: 'stick-sports',
    categories: {
      solutions: [
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Game Development', label: 'Game Development'},
      ],
      industries: [{value: 'Cocos2d-x', label: 'Cocos2d-x'}],
      technologies: [{value: 'Media & Entertainment', label: 'Media & Entertainment'}],
    },
  },
  {
    id: 'camaradly',
    caseStudyUrl: '',
    featuredImageSrc: 'camaradly.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/camaradly.png').default,
    projectDescription:
      'Camaradly is an employee-first feedback, engagement, and growth platform promoting regular feedback, ensuring a complete, and visible workplace environment.',
    projectName: 'camaradly',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Project Management', label: 'Project Management'},
        {value: 'MVP Development', label: 'MVP Development'},
      ],
      industries: [
        {
          value: 'Workplace Experience & Management',
          label: 'Workplace Experience & Management',
        },
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
        {value: 'Heroku', label: 'Heroku'},
      ],
    },
  },
  {
    id: 'novi',
    caseStudyUrl: '',
    featuredImageSrc: 'novi.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/novi.png').default,
    projectDescription:
      'NOVI is a game of visual intelligence. It’s new, modern artistic look and feel with black & white tile designs provide hours of escape and addictive fun.',
    projectName: 'novi',
    categories: {
      solutions: [
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Game Design', label: 'Game Design'},
        {value: 'Game Development', label: 'Game Development'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [{value: 'Unity', label: 'Unity'}],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
      ],
    },
  },
  {
    id: 'acciyo',
    caseStudyUrl: '',
    featuredImageSrc: 'acciyo.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/acciyo.png').default,
    projectDescription:
      'Acciyo allows you to open any story, at any time, with all the context you need to feel like you’ve been following along the whole time.',
    projectName: 'acciyo',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'MVP Development', label: 'MVP Development'},
        {
          value: 'Extensions and Integrations',
          label: 'Extensions and Integrations',
        },
      ],
      industries: [
        {value: 'Media & Entertainment', label: 'Media & Entertainment'},
        {
          value: 'News & Social Applications',
          label: 'News & Social Applications',
        },
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
      ],
    },
  },
  {
    id: 'bed-bath-beyond',
    caseStudyUrl: '',
    featuredImageSrc: 'bed-bath-beyond.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/bed-bath-beyond.png').default,
    projectDescription:
      "Bed Bath and Beyond's app lets end-users visualize products by virtually placing them in their own room, providing a personalized and interactive shopping experience.",
    projectName: 'bed-bath-beyond',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {value: 'Lifestyle', label: 'Lifestyle'},
        {value: 'Retail & eCommerce', label: 'Retail & eCommerce'},
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'ARKit', label: 'ARKit'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
      ],
    },
  },
  {
    id: 'ander',
    featuredImageSrc: 'ander.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/ander.png').default,
    projectDescription:
      'Ander is the first platform to unify life and work in a rewarding, playful, and personalized experience that keeps everyone truly engaged.',
    projectName: 'ander',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'User Interface', label: 'User Interface'},
      ],
      industries: [
        {
          value: 'Healthcare & Life Sciences',
          label: 'Healthcare & Life Sciences',
        },
        {value: 'Lifestyle', label: 'Lifestyle'},
      ],
      technologies: [
        {value: 'Ruby on Rails', label: 'Ruby on Rails'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
      ],
    },
  },
  {
    id: 'charadicon',
    featuredImageSrc: 'charadicon.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/charadicon.png').default,
    projectDescription:
      "Experience a new way to play charades. Players pair up in a two person team in a texting-style guessing game using 100's of unique and groovy emoticons.",
    projectName: 'charadicon',
    categories: {
      solutions: [
        {value: 'Game Development', label: 'Game Development'},
        {value: 'Staff Augmentation', label: 'Staff Augmentation'},
      ],
      industries: [{value: 'E-Sports & Gaming', label: 'E-Sports & Gaming'}],
      technologies: [{value: 'Unity', label: 'Unity'}],
    },
  },
  {
    id: 'audio-cardio',
    featuredImageSrc: 'audio-cardio.jpg',
    logoSrc: require('../../assets/images/portfolio/projects/logos/audio-cardio.png').default,
    projectDescription:
      "AudioCardio™ is a data and science-backed sound therapy designed to maintain and strengthen your hearing. It's like physical therapy for your hearing.",
    projectName: 'audio-cardio',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {
          value: 'Healthcare & Life Sciences',
          label: 'Healthcare & Life Sciences',
        },
      ],
      technologies: [
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {value: 'NodeJS', label: 'NodeJS'},
        {
          value: 'Cloud Firestore Database',
          label: 'Cloud Firestore Database',
        },
        {value: 'Django', label: 'Django'},
        {value: 'ReactJS', label: 'ReactJS'},
      ],
    },
  },
  {
    id: 'decorist',
    featuredImageSrc: 'decorist.png',
    logoSrc: require('../../assets/images/portfolio/projects/logos/decorist.png').default,
    projectDescription:
      'Decorist’s innovative room builder application that allows designers to get photorealistic renders of rooms in record time.',
    projectName: 'decorist',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {value: 'Retail & eCommerce', label: 'Retail & eCommerce'},
        {
          value: 'Photo Editing and Printing',
          label: 'Photo Editing and Printing',
        },
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
        {value: 'Unity', label: 'Unity'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
        {value: 'Jenkins', label: 'Jenkins'},
      ],
    },
  },
];

const INITIAL_FILTER = {
  solutions: [],
  industries: [],
  technologies: [],
};

const Portfolio = () => {
  const [filter, setFilter] = React.useState(INITIAL_FILTER);
  const [filteredProjects, setFilteredProjects] = React.useState(PROJECTS);

  useDebouncedEffect(
    () => {
      // if there are no filters applied, return all projects
      if (!filter.industries.length && !filter.solutions.length && !filter.technologies.length) {
        setFilteredProjects(PROJECTS);
      } else {
        // else return projects that match the filter
        const filteredProjects = PROJECTS.filter(project => {
          // We'll use Sets ( lookup O(1) ) instead of array ( lookup O(n) ) because of performance
          const solutionsSet = new Set(filter.solutions.map(val => val.value));
          const technologiesSet = new Set(filter.technologies.map(val => val.value));
          const industriesSet = new Set(filter.industries.map(val => val.value));

          // if project categories intersect (A ∩ B) with any Set, then return true
          if (
            project.categories.solutions.filter(solution => solutionsSet.has(solution.value))
              .length !== 0 ||
            project.categories.technologies.filter(technology =>
              technologiesSet.has(technology.value)
            ).length !== 0 ||
            project.categories.industries.filter(industry => industriesSet.has(industry.value))
              .length !== 0
          ) {
            return true;
          }

          return false;
        });

        setFilteredProjects(filteredProjects);
      }
    },
    800,
    [filter]
  );

  const filterChangeHandler = React.useCallback((newFilter, category) => {
    if (category === 'solution') {
      setFilter(filter => ({...filter, solutions: newFilter}));
    } else if (category === 'technology') {
      // flatten technologies
      const flatTechnologies = newFilter.reduce((acc, cur) => {
        if (cur.options) {
          return [...acc, ...cur.options];
        }
        return [...acc, cur];
      }, []);
      setFilter(filter => ({...filter, technologies: flatTechnologies}));
    } else if (category === 'industry') {
      setFilter(filter => ({...filter, industries: newFilter}));
    }
    // else {
    //   throw new Error(`Invalid catagory: ${category}`)
    // }
  }, []);

  const resetFilterHandler = React.useCallback(() => {
    setFilter(INITIAL_FILTER);
  }, [INITIAL_FILTER]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Portfolio | Tintash</title>
      </Helmet>
      <HeadingBanner
        heading="Portfolio"
        description="We’ve worked across multiple verticals and a range of services
                to create engaging and innovative digital experiences"
        imageSrc={require('../../assets/images/portfolio/topbanner-bg.jpg').default}
      />
      {/* Projects section */}
      <section style={{fontFamily: 'Lato, sans-serif'}} className="py-5">
        <div className="container pb-5">
          <FeaturedProjectsCarousel projects={FEATURED_PROJECTS} />
        </div>
        <div className="py-5">
          <ProjectFilter
            filter={filter}
            categories={CATEGORIES}
            onFilterChange={filterChangeHandler}
            onFilterReset={resetFilterHandler}
          />
        </div>
        <div className="py-5">
          <ProjectGrid projects={filteredProjects} />
        </div>
      </section>
    </>
  );
};

export default Portfolio;
