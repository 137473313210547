import React from 'react';
import PropTypes from 'prop-types';
import {default as ReactSelect} from 'react-select';

const CustomReactSelect = props => {
  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={[props.allOption, ...props.options]}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === props.allOption.value) {
              return props.onChange([props.allOption, ...props.options]);
            }

            const flatOptionsLength = props.options.reduce((acc, cur) => {
              if (cur.options) {
                return acc + cur.options.length;
              }
              return acc + 1;
            }, 0);

            let result = [];
            if (selected.length === flatOptionsLength) {
              if (selected.includes(props.allOption)) {
                result = selected.filter(option => option.value !== props.allOption.value);
              } else if (event.action === 'select-option') {
                result = [props.allOption, ...props.options];
              }
              return props.onChange(result);
            }
          }

          return props.onChange(selected);
        }}
      />
    );
  }

  return <ReactSelect {...props} />;
};

CustomReactSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

CustomReactSelect.defaultProps = {
  allOption: {
    label: 'Select all',
    value: '*',
  },
};

export default CustomReactSelect;
