import React from 'react';
import PropTypes from 'prop-types';
import {components} from 'react-select';
import Select from '../../../Shared/ReactSelect/ReactSelect';

const allOption = {
  label: 'Select all',
  value: '*',
};

const ValueContainer = ({children, ...props}) => {
  const currentValues = props.getValue();
  let toBeRendered = children;

  const styles = {
    ...props.getStyles('placeholder', props),
    ...props.selectProps.styles.placeholder(),
    color: '#000',
  };

  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  } else if (currentValues.length >= 3) {
    toBeRendered = [
      <div key="1" style={styles}>
        {`${currentValues.length} of ${props.options.length}`}
      </div>,
      children[1],
    ];
  } else if (currentValues.length !== 0) {
    toBeRendered = [
      <div key="1" style={styles}>
        {currentValues.map(val => val.label).join(', ')}
      </div>,
      children[1],
    ];
  }

  return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>;
};

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = 'All selected';
  }

  const styles = {
    ...props.getStyles('placeholder', props),
    ...props.selectProps.styles.placeholder(),
    color: '#000',
  };

  return <div style={styles}>{labelToBeDisplayed}</div>;
};

const Option = props => (
    <div
      ref={props.innerRef}
      {...props.innerProps}
      style={{
        WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        color: 'inherit',
        cursor: 'pointer',
        display: 'block',
        fontSize: 'inherit',
        padding: '0 12px',
        margin: '8px 0',
        userSelect: 'none',
        width: '100%',
      }}
      className="d-flex justify-content-start align-items-center"
    >
      <input
        style={{cursor: 'pointer'}}
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
      />
      <label
        className="mb-0"
        style={{
          overflowWrap: 'break-word',
          display: 'block',
          padding: '0 1rem',
          fontFamily: 'inherit',
          fontWeight: props.isSelected ? 'bold' : 'normal',
          fontStyle: 'normal',
          fontSize: '14px',
          letterSpacing: '0',
          color: props.isSelected ? '#000' : '#364750',
          cursor: 'pointer',
        }}
      >
        {props.label}
      </label>
    </div>
  );

const customStyles = {
  control: styles => ({
      ...styles,
      borderRadius: '4px',
      border: 'solid 1px #dfe4ec',
      backgroundColor: '#f9f9f9',
      borderColor: '',
      '&:hover': {},
    }),
  placeholder: styles => ({
    ...styles,
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#b8b8b8',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  menu: provided => ({...provided, zIndex: 5}),
};

const customComponents = {
  Option,
  MultiValue,
  ValueContainer,
  IndicatorSeparator: () => null,
  ClearIndicator: () => null,
};

const DropdownSelect = ({options, value, onChange, placeholder, ...props}) => (
    <Select
      isMulti
      allowSelectAll
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isSearchable={false}
      blurInputOnSelect={false}
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={onChange}
      styles={customStyles}
      components={customComponents}
      // menuIsOpen
      {...props}
    />
  );

DropdownSelect.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
};

export default DropdownSelect;
