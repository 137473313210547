import React from 'react';
import PropTypes from 'prop-types';
import * as classes from './HeadingBanner.module.css';

function HeadingBanner({heading, description, imageSrc}) {
  return (
    <section style={{backgroundImage: `url(${imageSrc})`}} className={classes.banner}>
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-12">
            <h1 className={`text-left text-white ${classes.heading}`}>{heading}</h1>
            <p className={`text-left text-white ${classes.description}`}>{description}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

HeadingBanner.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
};

export default HeadingBanner;
