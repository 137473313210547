import React from 'react';
import PropTypes from 'prop-types';
import * as classes from './ProjectFilter.module.css';

import DropdownSelect from '../DropdownSelect/DropdownSelect';

const labelStyle = {
  fontFamily: 'Lato, sans-serif',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#586065',
  paddingLeft: 8,
  paddingBottom: 8,
};

function ProjectFilter({filter, categories, onFilterChange, onFilterReset}) {
  return (
    <div className="container">
      <div className="row">
        <div className="mb-4 mb-md-0 col-12 col-md-3">
          <div>
            <div style={labelStyle}>Solution</div>
            <DropdownSelect
              options={categories.solutions}
              value={filter.solutions}
              onChange={value => onFilterChange(value, 'solution')}
              placeholder="Select Solution"
            />
          </div>
        </div>
        <div className="mb-4 mb-md-0 col-12 col-md-3">
          <div>
            <div style={labelStyle}>Technology</div>
            <DropdownSelect
              options={categories.technologies}
              value={filter.technologies}
              onChange={value => onFilterChange(value, 'technology')}
              placeholder="Select Technology"
            />
          </div>
        </div>
        <div className="mb-5 mb-md-0 col-12 col-md-3">
          <div>
            <div style={labelStyle}>Industry</div>
            <DropdownSelect
              options={categories.industries}
              value={filter.industries}
              onChange={value => onFilterChange(value, 'industry')}
              placeholder="Select Industry"
            />
          </div>
        </div>
        <div className="col-12 col-md-3 d-flex align-items-end">
          <button
            onClick={() => onFilterReset()}
            className={`btn btn-primary w-100 ${classes.button}`}
          >
            All
          </button>
        </div>
      </div>
    </div>
  );
}

ProjectFilter.propTypes = {};

export default ProjectFilter;
