import React from 'react';
import {MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView} from 'mdbreact';
import FeaturedProjectCard from '../../../Shared/FeaturedProjectCard/FeaturedProjectCard';
import './FeaturedProjectsCarousel.css';

export default function FeaturedProjectsCarousel({projects}) {
  return (
    <MDBCarousel
      activeItem={1}
      length={projects.length}
      showControls={false}
      showIndicators
      interval={7000}
      className="carousel"
    >
      <MDBCarouselInner>
        {projects.map((project, index) => (
          <MDBCarouselItem key={index + 1} itemId={index + 1}>
            <MDBView>
              <FeaturedProjectCard {...project} />
            </MDBView>
          </MDBCarouselItem>
        ))}
      </MDBCarouselInner>
    </MDBCarousel>
  );
}
