import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import * as classes from './ProjectCard.module.css';

function ProjectCard({
  projectName,
  projectDescription,
  logoSrc,
  featuredImageSrc,
  solutionCategories,
  isLogoRound,
  projectDescriptionClass,
  featuredImageLoading,
}) {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "portfolio/projects/featured-images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const match = React.useMemo(
    () =>
      data.allFile.edges.find(
        ({node}) => `portfolio/projects/featured-images/${featuredImageSrc}` === node.relativePath
      ),
    [data, featuredImageSrc]
  );
  return (
    <article className={`${classes.card}`}>
      <header className={classes.header}>
        <div className={classes.featuredImage}>
          <Img
            className={classes.featuredImage}
            imgStyle={{objectFit: 'cover'}}
            placeholderStyle={{objectFit: 'cover'}}
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={match.node.childImageSharp.fluid}
            alt={projectName}
            fadeIn={false}
            loading={featuredImageLoading}
          />
        </div>
        <div
          className={`${classes.logoContainer} ${
            isLogoRound ? classes.roundLogo : classes.rectLogo
          }`}
        >
          <img className={classes.logo} src={logoSrc} alt={projectName} />
        </div>
      </header>
      <div style={{padding: 30, paddingTop: 50, paddingBottom: 20}}>
        <section>
          <div className={`${classes.projectDescription} ${projectDescriptionClass ?? ''} m-0`}>
            {projectDescription}
          </div>
        </section>
        {solutionCategories && (
          <footer style={{paddingTop: 30}}>
            <div className={classes.tagsContainer}>
              {solutionCategories.map(sol => (
                <div key={sol.label} className={classes.badge}>
                  <span className={classes.badgeText}>{sol.label}</span>
                </div>
              ))}
            </div>
          </footer>
        )}
      </div>
    </article>
  );
}

ProjectCard.propTypes = {
  logoSrc: PropTypes.string.isRequired,
  featuredImageSrc: PropTypes.string.isRequired,
  solutionCategories: PropTypes.array,
  projectName: PropTypes.string.isRequired,
  projectDescription: PropTypes.string.isRequired,
  caseStudyUrl: PropTypes.string,
  isLogoRound: PropTypes.bool,
  featuredImageLoading: PropTypes.oneOf(['lazy', 'eager']),
};

ProjectCard.defaultProps = {
  isLogoRound: false,
  featuredImageLoading: 'eager',
};

export default ProjectCard;
