import React from 'react';
import StackGrid from 'react-stack-grid';
import Project from '../../../Shared/ProjectCard/ProjectCard';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

function ProjectGrid({projects}) {
  const {width} = useWindowDimensions();

  return (
    <div className="container">
      <StackGrid
        columnWidth={width <= 992 ? '100%' : '50%'}
        gutterWidth={20}
        gutterHeight={40}
        duration={800}
        monitorImagesLoaded
      >
        {projects.map(project => (
          <div key={project.id}>
            <Project {...project} solutionCategories={project.categories.solutions} />
          </div>
        ))}
      </StackGrid>
    </div>
  );
}

export default ProjectGrid;
